import type { MouseEvent, ReactNode } from 'react'
import { forwardRef } from 'react'

import { Icons } from '../icons'
import { FadeStickLoader, LoaderColor } from '../loaders'

export type ButtonShape = 'default' | 'circle' | 'square'
export type ButtonSize = 'sm' | 'md' | 'lg'
type IconButtonShape = Exclude<ButtonShape, 'default'>
export type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'subtle'
export type ButtonLabelColor =
  | 'theme'
  | 'danger'
  | 'void'
  | 'muted'
  | 'energy'
  | 'success'
  | 'paragraphs'

type ButtonProps = {
  id?: string
  variant?: ButtonVariant
  size?: ButtonSize
  wFull?: boolean
  color?: ButtonLabelColor
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  onMouseOver?: (event: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  loading?: boolean
  iconLeft?: keyof typeof Icons
  iconRight?: keyof typeof Icons
  title?: string
  children?: ReactNode
}

type IconButtonProps = Omit<ButtonProps, 'iconLeft' | 'iconRight' | 'wFull'> & {
  shape: IconButtonShape
} & {
  icon?: keyof typeof Icons
}

const VariantStyles = {
  default: {
    primary:
      'bg-theme-main border border-solid border-theme-main hover:bg-theme-strong hover:border-theme-strong',
    secondary: 'bg-void border border-solid border-main hover:bg-weak-2 hover:border-main',
    danger:
      'bg-red-main border border-solid border-red-main hover:bg-red-strong hover:border-red-strong',
    subtle:
      'bg-transparent border border-solid border-transparent hover:bg-opacity-[15%] hover:bg-black',
  },
  icon: {
    primary:
      'bg-theme-main border border-solid border-theme-main hover:bg-theme-strong hover:border-theme-strong',
    secondary: 'bg-void border border-solid border-main hover:border-strong',
    danger:
      'bg-red-main border border-solid border-red-main hover:bg-red-strong hover:border-red-strong',
    subtle:
      'bg-transparent border border-solid border-transparent hover:bg-opacity-[15%] hover:bg-black',
  },
}

const ColorStyles = {
  theme: 'text-theme-main',
  danger: 'text-red-main',
  void: 'text-void',
  muted: 'text-muted',
  energy: 'text-orange-main',
  success: 'text-green-main',
  paragraphs: 'text-paragraphs',
  'primary-variant': 'text-void',
  'secondary-variant': 'text-header',
  'danger-variant': 'text-void',
  'subtle-variant': 'text-header',
}

const ShapeStyles = {
  default: 'rounded-md',
  circle: 'rounded-full',
  square: 'rounded-md',
}

const SizeStyles = {
  default: {
    sm: 'h-6 py-0.5 px-2 text-xs',
    md: 'h-8 py-1.5 px-4 text-sm',
    lg: 'h-10 py-2 px-4 text-base',
  },
  icon: {
    sm: 'h-6 w-6 p-1.5',
    md: 'h-8 w-8 p-1.5',
    lg: 'h-10 w-10 p-1.5',
  },
}

const LoaderColorStyles: Record<ButtonVariant, LoaderColor> = {
  primary: 'void',
  secondary: 'header',
  danger: 'void',
  subtle: 'header',
}

const checkIsIconButton = (props: ButtonProps | IconButtonProps): props is IconButtonProps =>
  'shape' in props && Boolean(props.shape)

const Button = forwardRef<HTMLButtonElement, ButtonProps | IconButtonProps>((props, ref) => {
  const { variant = 'primary', size = 'md', color, onClick, onMouseOver, disabled, loading } = props

  const isIconButton = checkIsIconButton(props)

  const content = (
    <>
      {!isIconButton && 'iconLeft' in props && props.iconLeft
        ? Icons[props.iconLeft]({
            className: `w-4 h-4 ${loading ? 'invisible' : ''}`,
          })
        : null}
      {isIconButton && 'icon' in props && props.icon ? (
        Icons[props.icon]({
          className: `${size === 'lg' ? 'w-5 h-5' : 'w-4 h-4'} ${loading ? 'invisible' : ''}`,
        })
      ) : (
        <span className={`${loading ? 'invisible' : ''}`}>{props.children}</span>
      )}
      {!isIconButton && 'iconRight' in props && props.iconRight
        ? Icons[props.iconRight]({
            className: `w-4 h-4 ${loading ? 'invisible' : ''}`,
          })
        : null}
    </>
  )

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!loading && onClick) {
      onClick(e)
    }
  }

  return (
    <button
      id={props.id}
      ref={ref}
      disabled={disabled}
      title={props.title}
      className={`relative box-border flex cursor-pointer items-center justify-center whitespace-nowrap 
        ${VariantStyles[isIconButton ? 'icon' : 'default'][variant]}
        ${ColorStyles[color ?? `${variant}-variant`]}
        ${'wFull' in props && props.wFull && !('icon' in props) ? 'w-full' : 'w-fit'}
        ${SizeStyles[isIconButton ? 'icon' : 'default'][size]} 
        ${ShapeStyles[isIconButton ? props.shape : 'default']} 
        disabled:bg-weak-2 disabled:border-main disabled:text-main disabled:cursor-not-allowed disabled:shadow-none ${
          loading ? 'cursor-progress' : ''
        }
        space-x-1`}
      onClick={handleClick}
      onMouseOver={onMouseOver}>
      {content}
      {loading && <FadeStickLoader color={LoaderColorStyles[variant]} />}
    </button>
  )
})

export { Button }
