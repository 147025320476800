import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMemo, useRef, useState } from 'react'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'

import { Controls } from './Controls'
import { formatDuration } from './helpers'
import { useWaveSurfer } from './useWaveSurfer'

const WAVEFORM_STYLE_OVERRIDES = {
  '& ::part(wrapper)': { backgroundColor: '#EDE7F6', borderRadius: '4px' },
  '& ::part(progress)': {
    backgroundColor: 'primary.100',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '& ::part(timeline)': { zIndex: 10 },
  '& ::part(cursor):after': {
    content: '""',
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '8px solid rgba(255, 0, 0, 0.5)',
  },
}

const Player = ({
  visible,
  recordedAudioProperties,
  handleRecordReset,
}: {
  visible: boolean
  recordedAudioProperties: { url: string; fileName: string } | null
  handleRecordReset: () => Promise<void>
}) => {
  const playbackContainerRef = useRef<HTMLDivElement | null>(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const actualFileName = recordedAudioProperties?.fileName.split('/').pop()

  const options = useMemo(
    () => ({
      progressColor: 'rgba(96, 76, 165, 1)',
      waveColor: '#9c8fcc',
      cursorColor: 'rgba(255, 0, 0, 0.5)',
      cursorWidth: 2,
      barWidth: 4,
      barGap: 10,
      barRadius: 4,
      height: 100,
      barHeight: 0.7,
      url: recordedAudioProperties?.url,
      plugins: [
        TimelinePlugin.create({
          insertPosition: 'beforebegin',
          formatTimeCallback: formatDuration,
        }),
      ],
    }),
    [recordedAudioProperties?.url]
  )

  const { waveSurfer, playbackProgress, audioLength } = useWaveSurfer({
    container: playbackContainerRef.current,
    options,
  })

  return (
    <>
      <Box display={visible ? 'block' : 'none'}>
        <Typography fontSize='14px' mb='16px'>
          {actualFileName}
        </Typography>
        <Box ref={playbackContainerRef} sx={WAVEFORM_STYLE_OVERRIDES} />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mt='4px'
          position='relative'>
          <Controls waveSurfer={waveSurfer} />
          <Typography
            fontWeight={500}
            fontSize='16px'
            position='absolute'
            left='50%'
            sx={{ transform: 'translateX(-50%)' }}>
            {formatDuration(playbackProgress)}/{formatDuration(audioLength)}
          </Typography>
          <Button
            color='error'
            startIcon={<DeleteOutline />}
            size='small'
            onClick={() => setIsDeleteDialogOpen(true)}
            sx={{ fontWeight: 'normal' }}>
            Delete
          </Button>
        </Stack>
      </Box>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete recording</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete{' '}
            <Typography component='span' fontWeight='bold'>
              {actualFileName}
            </Typography>
            ? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              handleRecordReset()
              setIsDeleteDialogOpen(false)
            }}
            autoFocus>
            Confirm & delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { Player }
