export const PROCESS_STATUS = ['draft', 'active', 'archived', 'testing', 'inactive'] as const

export const ADD_NUMBERS_ASYNC_STEP_TEMPLATE_ID = '9a5bd9b9-82c1-461c-9ba6-372aa920f151'
export const ADD_NUMBERS_STEP_TEMPLATE_ID = 'd6a784ee-d042-49e7-b5d6-3b9475a6404d'
export const SUPER_ADMIN_ROLE_ID = 'cf9c5844-3de6-4501-a5c3-1cf1b70da9d1'
export const AND_STEP_TEMPLATE_ID = 'f39fb44a-3a12-41bb-95c9-2f375518d2ec'
export const BOLT_MENUS_TEAM_ROLE_ID = '93a6e881-f5ec-4dd2-b0b2-4ca8e28e4cbf'
export const BRANCH_STEP_TEMPLATE_ID = '90aa1c82-9d9c-4c48-a62a-8103ca2df3e8'
export const BUNGALOW_TEAM_ROLE_ID = '096a2003-7753-4e48-a828-2e9cbe658e4a'
export const CRON_TRIGGER_STEP_TEMPLATE_ID = '3f5e7b18-7135-4dff-b0b8-10174d24d547'
export const END_MAP_STEP_TEMPLATE_COUNT_VARIABLE_ID = '0107d96b-4037-4f43-b9cb-1ecf5cb11335'
export const END_MAP_STEP_TEMPLATE_ID = 'b311d851-0f5d-4796-8de6-e906dc08a8b2'
export const END_BASE_RUN_STEP_TEMPLATE_ID = 'cb8f6725-39aa-4b90-b265-802beff70471'
export const END_PROCESS_STEP_TEMPLATE_ID = '7e820daf-1a7d-4f06-b0cb-1830b2e787d4'
export const AUDIT_STEP_TEMPLATE_ID = 'a70e7ae6-1c16-4c6d-8f6b-3f2ab1237622'
export const MANUAL_TRIGGER_PAYLOAD_STEP_TEMPLATE_VARIABLE_ID =
  '6801f6cb-0ba4-45e3-a418-71709e4c40b5'
export const MANUAL_TRIGGER_STEP_TEMPLATE_ID = '849c2208-32b4-48db-90f4-79a360140d32'
export const MAP_STEP_TEMPLATE_COUNT_VARIABLE_ID = '38a8be11-7e72-4791-91ff-43cc984b6492'
export const ATTENDED_MAP_STEP_TEMPLATE_ID = 'c92235df-ed0d-42eb-88b6-2704d19842dd'
export const ATTENDED_MAP_STEP_TEMPLATE_COUNT_VARIABLE_ID = '5b5b8f6e-f118-437d-99fd-e1e24fa980bc'
export const END_ATTENDED_MAP_STEP_TEMPLATE_ID = 'e074553e-e66a-448c-a9cb-5443e698841b'
export const SHADOW_STEP_TEMPLATE_ID = '83f9d063-eb04-40dd-9c89-9ba53140e476'
export const MAP_STEP_TEMPLATE_ID = '2871683a-a2b6-4767-9fdf-82821a1c6fa9'
export const MAP_JSON_TO_VARIABLES_STEP_TEMPLATE_ID = 'cf647ec3-d31d-48bb-af65-2ebe9a203fec'
export const JSON_BUILDER_STEP_ID = 'fa8309f9-edb1-4d7c-9629-685a93bd5b55'
export const SFDC_QUERY_EXECUTOR_STEP_TEMPLATE_ID = 'cc1a96d7-a775-4e67-a2f2-aa5d9604cc41'
export const SFDC_UPDATE_OBJECT_STEP_TEMPLATE_ID = 'e94cf3bf-3573-40ad-906d-c9759cf8a12e'
export const UPDATE_BASE_VARIABLE_VALUES_STEP_TEMPLATE_ID = '8a827c63-5336-48a6-836b-cafc9dd0dc29'
export const GET_STEP_RUN_DETAIL_STEP_TEMPLATE_ID = '9474b148-b126-4b9f-9f88-75e26647180a'
export const NOP_STEP_TEMPLATE_ID = '707d0a7a-bb3a-4a1f-8c1a-aa3c0a19b9b1'
export const SECURITY_OPS_TEAM_ROLE_ID = '01777ffe-68fc-40f1-b658-9d7dfb887934'
export const SET_BRV_VAL_TO_STEP_RUN_ID_STEP_TEMPLATE_ID = '1f1c13bd-e28a-4e8c-8332-106b228aeb6c'
export const SET_STEP_RUN_STATUS_STEP_TEMPLATE_ID = '856a4bcf-e44c-47fc-ae92-0fa5c2b4f196'
export const START_PROCESS_STEP_TEMPLATE_ID = 'd230ee7a-dd0d-46e0-b86f-27fc2bc3c269'
export const PAUSE_STEP_TEMPLATE_ID = '3eb0fae2-93c6-494a-96af-efac9a39bdbb'
export const PENDING_BASE_RUN_CHECK_STEP_TEMPLATE_ID = '64146a17-3e58-4db4-b8bd-6477551773ce'
export const WIZARDLESS_MANUAL_STEP_TEMPLATE_ID = '11ef0ccb-c9d5-4d2a-8ee3-6439c904e92d'
export const AGENT_ADMIN_ROLE_ID = '15eded59-9744-4c2d-9333-5a70df946ccb' as const
export const AGENT_SQUAD_LEAD_ROLE_ID = '6a9fa27e-c680-4730-a995-dc6f12966a8e' as const
export const AGENT_TEAM_LEAD_ROLE_ID = '6c45fc15-5d65-44b3-b20a-6c2eedea858e' as const
export const AGENT_OPERATOR_ROLE_ID = 'f6fc38be-5aea-47c2-a241-1822d13d2922' as const
export const CLIENT_ADMIN_ROLE_ID = '8b0be2ae-b8be-418f-b53e-8686e79a1dc1' as const
export const CLIENT_LEAD_ROLE_ID = '94160450-44e5-4d70-9301-a818ebb013af' as const
export const CLIENT_OPERATOR_ROLE_ID = '9b32f5a3-23d5-4376-a03f-1d8e60e69885' as const
export const WEBHOOK_TRIGGER_STEP_TEMPLATE_ID = '34ff77b3-2835-4f6c-a78c-93f01bd2f1e6'
export const HTTP_STEP_TEMPLATE_ID = '37fc6f7e-fb04-4f99-baa3-1caaf1b80b00'

export const LLMAAS_STEP_TEMPLATE_ID = '96a16364-a68e-463e-b786-7ad93ac8950f'

export const STEP_TEMPLATE_ORIGIN_TAG_ID = '860d5872-42d8-4f0b-8def-d36c1539dd88' as const
export const FUNCTION_TAG_ID = 'ba9deb23-4db9-47c7-ae49-45ea67aac9b5' as const
export const INTEGRATION_TAG_ID = 'bc7b1336-7e05-41bc-98d3-4cbdfe91875a' as const
export const DATA_VERIFICATION_TAG_ID = 'd60a0918-9f19-41a6-b38f-34bd48e23dcd' as const
export const SCHEDULING_TAG_ID = 'b434f572-0537-4ab3-9fe0-61820ffb86af' as const
export const COMMUNICATION_TAG_ID = '42ec08e6-fa55-41cb-aed7-2d268bb4c794' as const
export const DATA_ENRICHMENT_TAG_ID = 'b5017cb5-bc21-4665-a5e8-78d4c2dd13ef' as const
export const QUALITY_ASSURANCE_TAG_ID = 'bb7ce251-075e-4086-a8f6-5feec8371ec7' as const
export const DATA_SOURCING_TAG_ID = '5ec87a29-0216-413c-8452-eae8edc98e7c' as const
export const DATA_ENTRY_TAG_ID = '0be3ad2c-bd69-4286-931c-3fe65e783675' as const
export const DATA_TRANSFORMATION_TAG_ID = '74b7b90b-c0ba-4f02-9654-c38dade5ea3e' as const
export const IDENTITY_AND_SECURITY_TAG_ID = '2f83c54d-926f-4862-b100-e627dc7ac025' as const

export const NOTION_TOOL_ID = '336cadc8-40d8-4bd9-9e3a-01ab82dd5d50' as const
export const LOOM_TOOL_ID = 'f1d18cc9-12dd-4f9b-96bb-6599ea271629' as const
export const MICROSOFT_OFFICE_TOOL_ID = '359ae007-a4da-40e2-8b79-5ca4139c9162' as const
export const AIRTABLE_TOOL_ID = 'c9cf604d-9364-4d72-8952-2aa32541306c' as const
export const MAILCHIMP_TOOL_ID = '1e528696-a87c-47ed-8a01-2afe4cc8ce88' as const
export const CALENDLY_TOOL_ID = '037bec80-5d83-4be7-876f-9e49e9dfddc3' as const
export const SLACK_TOOL_ID = 'ed4e9eef-7cf6-4bd0-992b-eb1c4cb766c5' as const
export const ATLASSIAN_TOOL_ID = 'f8a5437c-ea56-44dc-bc80-44e63d90610a' as const
export const GITHUB_TOOL_ID = 'bdbe38f4-018a-4740-96fd-31ab06871893' as const
export const GOOGLE_SHEETS_TOOL_ID = 'aabec9a3-11f2-483c-a521-4be1c9fcfd1e' as const

export const AUTOMATED_CATEGORY_ID = '95c034d2-6132-4681-a90f-df4ad9acb24c' as const
export const MANUAL_CATEGORY_ID = 'de5b5fe0-0e57-4dce-93e2-7da1fad2faec' as const
export const TRIGGER_CATEGORY_ID = 'cfabcc7d-44cf-400d-a6a6-e3d32178a52e' as const
export const CLIENT_CATEGORY_ID = '88f96cfb-9daf-443b-9297-1685f102c5f5' as const
export const CONTROL_CATEGORY_ID = '92095a43-588a-4ae4-ab64-f505e6c2149d' as const
export const SHOULD_EXECUTE_STEP_STEP_TEMPLATE_ID = '956a4bcf-e44c-47fc-ae92-0fa5c2b4f196'

export const ADD_NUMBERS_STEP_TEMPLATE_VARIABLES_IDS = {
  FirstNumber: '215b6f23-f103-46ae-b609-24e540e728ed',
  SecondNumber: '2ba8d151-1185-4e55-abd2-06b82264eb38',
  Sum: 'c9b3ede8-5784-46eb-8867-b33434b88ee6',
}

export const ADD_NUMBERS_ASYNC_STEP_TEMPLATE_VARIABLES_IDS = {
  FirstNumber: '02B38BCC-AB87-4F12-82F6-11A8DC29BEA3',
  SecondNumber: '8DB5DE5B-A3F2-409D-8CDA-D66B891D6723',
  Sum: '6BC38F36-28CA-4B77-81F0-713D1360482E',
}

export const SET_STEP_RUN_STATUS_STEP_TEMPLATE_VARIABLES_IDS = {
  TargetStepRunId: 'bb772c93-9ad3-43ad-b08d-e7facd43febe',
  Status: '3379b56b-29c9-4100-90d1-3cf45c76257d',
}

export const SET_BRV_VAL_TO_STEP_RUN_ID_STEP_TEMPLATE_VARIABLES_IDS = {
  BaseVariableId: 'd66aa28f-250a-4266-b44b-fd3a8773b0de',
  StepId: '0b2e651d-5725-4c44-8a25-41bde2fdcde9',
}

export const AUDIT_STEP_TEMPLATE_VARIABLE_IDS = {
  NumberOfErrors: '30542391-dc5c-4909-8103-95b6c6ee7550',
  Status: '603276b3-bade-488d-bca0-5d89dde763f4',
  Notes: '81fddd48-b465-4970-8a4e-2885391a5ea4',
}

export const HTTP_STEP_TEMPLATE_VARIABLE_IDS = {
  url: 'de681187-6a6f-42c7-adf6-0d5587adb7a4',
  requestHeaders: '32101e0c-93da-4c40-8609-137f03ffda58',
  queryParameters: 'f4f30e86-9a49-44f7-9323-c9be5917b27c',
  cookies: 'e1497aab-0c88-4ea3-997c-23c9d9fa1549',
  requestBody: '19907665-fe98-4025-8eaf-36a2d7e0fd00',
  responseCode: '65455e07-eca0-4f8c-a624-b7a64dcd6acc',
  responseBody: '1b111753-5f9a-4cef-9839-206f8f84a605',
  responseHeaders: 'a4be1614-9393-41db-a5dc-3aba6b7b65ea',
}

export const LLMAAS_STEP_TEMPLATE_VARIABLE_IDS = {
  messages: 'fd8f235c-4e8f-49f1-82e3-4bb1445ca2b5',
  responseText: '917b5fb2-e4cd-4326-bbea-8befd5f69ef0',
  totalTokens: '4b39c2c9-f590-432a-b3d9-ba3ad61bdd2c',
  completionTokens: '0d117d11-f141-4068-96d6-49bba25df624',
  promptTokens: '9d74abeb-23c4-4992-81f2-ce48620d7701',
}

export const SFDC_QUERY_EXECUTOR_VARIABLE_IDS = {
  query: 'b24ae7e0-621a-4480-9611-ece44f8081af',
  records: '7ff19359-3d55-4a06-91c9-fb33c2ceb0f7',
  noOfRecords: '76e29873-99f0-4bd0-9bf6-43051d53842d',
}

export const SFDC_UPDATE_OBJECT_VARIABLE_IDS = {
  soObjectName: '5b2737d3-a2b7-47e3-bbbd-e2ddde140c8f',
  soObjectId: '31b97441-e4b6-4688-84ee-d386a8dc2e20',
  requestBody: '6699089e-c458-45a8-8b0b-4e6230297331',
  success: '847da1d0-bbd9-4d57-b6b6-b6ac5cc75b84',
}

export const SEND_EMAIL_WIZARD_CONFIG_ID = 'e0f74b36-ea0d-48c0-9e45-f84ea0985f9b'

// PROCESS SECURITY QUESTIONNAIRE CONSTANTS
export const PROCESS_QUESTIONNAIRE_WEBHOOK_TRIGGER_ID = '2d294e30-e957-4e42-90a3-e73fda7a5dd3'
export const PROCESS_SECURITY_MANAGEMENT = 'b7df6ba1-d5f5-4bdd-9001-f888eb2f596e'
export const DATA_PROTECTION_IMPACT_ASSESSMENT = '427dd4d0-d8df-477b-9406-7cf188040331'
export const PROCESS_SECURITY_MANAGEMENT_NULLIFIER = '0e791ed1-bf0d-45e9-984d-f7f81f11cb11'
export const DPO_PSQ_AUDIT_PASS_BASE_VARIABLE = '2808ab92-cbc2-477f-9482-7eb15d7b983a'
export const DPIA_RUN_ID_BASE_VARIABLE = '0232249b-5ecf-4c2c-a5bf-615e237140e5'
export const QUESTIONNAIRE_RUN_ID_BASE_VARIABLE = 'b393a330-2ed1-4e19-8997-e1ac6e75b03e'
export const DPO_DPIA_AUDIT_PASS_BASE_VARIABLE = 'c7249f30-58e1-4f41-93a0-0fda70a93f5e'
export const DPO_PSQ_AUDIT_STEP = 'fb6da005-f08c-4e85-8e97-4dd64536cc01'
export const DPO_DPIA_AUDIT_STEP = 'd5ac1087-0788-447a-9f30-e33c98f4180c'
export const PO_COMPLETES_DPIA_STEP = '6bc370ba-f1af-4ab3-9db6-46c3cdc7e1b7'

export const STEPS_WITH_STATIC_AUTOMATION_PARAMS = [
  HTTP_STEP_TEMPLATE_ID,
  SFDC_QUERY_EXECUTOR_STEP_TEMPLATE_ID,
  SFDC_UPDATE_OBJECT_STEP_TEMPLATE_ID,
]
