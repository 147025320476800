import { TStepRun } from '@invisible/common/components/providers/active-wizard-provider'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { MicSwitcher } from './MicSwitcher'
import { Player } from './Player'
import { Recorder } from './Recorder'

type TConfig = WizardSchemas.RecordAudio.TSchema
type TBaseRun = TBaseRunQueryData['items'][number]

interface IProps {
  recordAudio: TConfig
  stepRun: TStepRun
  companyName: string
  baseRun: TBaseRun
}

const RecordAudioWac = ({ recordAudio, stepRun, companyName, baseRun }: IProps) => {
  const { query } = useRouter()
  const baseRunVariable = baseRun.baseRunVariables.find(
    (v) => v.baseVariableId === recordAudio.recordedAudioBaseVariableId
  )
  const gcsDirectory = `${query.id}/${stepRun.baseRunId}/${stepRun.stepId}`

  const [selectedMic, setSelectedMic] = useState<{ id: string; label: string } | null>(null)
  const [recordedAudioProperties, setRecordedAudioProperties] = useState<{
    url: string
    fileName: string
  } | null>(null)
  const [shouldShowPlayer, setShowPlayer] = useState(false)
  const [recorderKey, setRecorderKey] = useState(0)

  const reactQueryClient = useQueryClient()

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const { data } = useQuery('get-recorded-file-signed-url', async () => {
    if (recordedAudioProperties || !baseRunVariable?.value) return

    // value is in the format of { url: 'gs://bucket-name/file-name' }
    const matches = (baseRunVariable.value as Record<string, string>)?.url?.match(
      /gs:\/\/([^/]+)\/(.+)/
    )
    if (!matches) return

    const searchParams = new URLSearchParams()
    searchParams.set('file', matches[2])
    searchParams.set('type', 'audio/webm')
    searchParams.set('bucketName', matches[1])
    searchParams.set('action', 'read')
    searchParams.set('excludeContentType', 'true')
    const { data } = await axios.post(`/api/google/cloud/signedUrl?${searchParams.toString()}`)
    return { url: data.url, fileName: matches[2] }
  })

  // Set recorded audio properties if data is available (e.g when the page is refreshed after recording)
  useEffect(() => {
    if (!data) return
    setRecordedAudioProperties((p) => (!p ? { url: data.url, fileName: data.fileName } : p))
    setShowPlayer(true)
  }, [data])

  const handleRecordSave = async ({
    url,
    fileName,
    length,
    micLabel,
  }: {
    url: string
    fileName: string
    length: number
    micLabel: string
  }) => {
    if (!recordAudio.recordedAudioBaseVariableId) return

    await updateVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: stepRun.baseRunId,
          baseVariableId: recordAudio.recordedAudioBaseVariableId,
          value: { url, length, fileName, mic: micLabel },
        },
      ],
    })
  }

  const handleRecordReset = async () => {
    if (!recordAudio.recordedAudioBaseVariableId) return

    setRecordedAudioProperties(null)
    setShowPlayer(false)
    await updateVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: stepRun.baseRunId,
          baseVariableId: recordAudio.recordedAudioBaseVariableId,
          value: null,
        },
      ],
    })
  }

  const resetRecorder = () => {
    setRecorderKey((p) => p + 1)
  }

  return (
    <MUIThemeProvider>
      <Box
        width='100%'
        height='100%'
        bgcolor='white'
        borderRadius='4px'
        p='8px 16px'
        boxSizing='border-box'>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb='16px'>
          <Typography fontSize='18px' fontWeight={500}>
            Record audio
          </Typography>
          <MicSwitcher selectedMicId={selectedMic?.id ?? ''} setSelectedMicId={setSelectedMic} />
        </Stack>
        <Recorder
          key={recorderKey}
          visible={!recordedAudioProperties || !shouldShowPlayer}
          maxLength={recordAudio.maxAudioLength}
          minLength={recordAudio.minAudioLength}
          companyName={companyName}
          fileNameSegments={recordAudio.fileNameSegments ?? []}
          setRecordProperties={(data) => setRecordedAudioProperties(data)}
          selectedMicId={selectedMic?.id ?? ''}
          selectedMicLabel={selectedMic?.label ?? ''}
          showPlayer={() => setShowPlayer(true)}
          gcsDirectory={gcsDirectory}
          stepName={stepRun.step.name}
          handleRecordSave={handleRecordSave}
          resetRecorder={resetRecorder}
        />
        <Player
          recordedAudioProperties={recordedAudioProperties}
          visible={Boolean(recordedAudioProperties?.url) && shouldShowPlayer}
          handleRecordReset={handleRecordReset}
        />
      </Box>
    </MUIThemeProvider>
  )
}

export { RecordAudioWac }
