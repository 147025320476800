import { formatInTimeZone } from '@invisible/common/date'
import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  toGlobalId,
  useExportTasksWithReviewsQuery,
} from '@invisible/concorde/gql-client'
import { TimezoneToolbar } from '@invisible/ui/datagrid-toolbars'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import InfoIcon from '@mui/icons-material/Info'
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined'
import ReplayIcon from '@mui/icons-material/Replay'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { IconButton, ToolbarProps, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DataGridPro,
  GridColDef,
  GridFilterItem,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { compact, isNil, set, startCase } from 'lodash/fp'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useGate } from 'statsig-react'

import { BaseRunsApproveDialog, IExportTaskToApprove } from './BaseRunsApproveDialog'
import { useGenerateSignedUrl } from './hooks/useGenerateSignedUrl'
import { useRetryExport } from './hooks/useRetryExport'
import { ViewExportTaskDialog } from './readOnly/ViewExportTaskDialog'
import { ReviewExportTaskDialog } from './review/ReviewExportTaskDialog'

interface IProps {
  processId: string
}

export interface IExportTaskInReview {
  exportTaskId?: string
  exportTaskName?: string
}

enum IDataDeliveryExportReviewStatusEnum {
  /** Approved */
  Approved = 'approved',
  /** Delivered */
  Delivered = 'delivered',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected',
}

type TRow = {
  id: string
  name: string
  status: string
  type: string
  exportName: string
  createdAt: Date
  initiatedBy: string
  fileSize: string
  reviewStatus: IDataDeliveryExportReviewStatusEnum
  reviewedByUser: string
  reviewedAt?: Date
  notes: string
}

const GRID_STYLES = {
  '& .MuiDataGrid-actionsCell': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover': {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'visible',
    },
  },
}

const REVIEW_STATUS_TEXT_PROPS = [
  {
    status: IDataDeliveryExportReviewStatusEnum.Pending,
    icon: <WarningAmberOutlinedIcon sx={{ fontSize: '20px', color: '#EF6C00' }} />,
    color: '#EF6C00',
    opacity: 1,
  },
  {
    status: IDataDeliveryExportReviewStatusEnum.Approved,
    icon: <DoneIcon sx={{ fontSize: '20px', color: 'black' }} />,
    color: 'black',
    opacity: 1,
  },
  {
    status: IDataDeliveryExportReviewStatusEnum.Rejected,
    icon: <CloseIcon sx={{ fontSize: '20px', color: 'black' }} />,
    color: 'black',
    opacity: 0.4,
  },
]

export const computeFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} B`
  }
  const kbSize = size / 1024
  if (kbSize < 1024) {
    return `${kbSize.toFixed(2)} KB`
  }
  const mbSize = kbSize / 1024
  if (mbSize < 1024) {
    return `${mbSize.toFixed(2)} MB`
  }
  const gbSize = mbSize / 1024
  return `${gbSize.toFixed(2)} GB`
}

const INITIAL_TABLE_STATE = {
  sorting: { sortModel: [{ field: 'createdAt', sort: 'desc' as GridSortDirection }] },
}

// Maps the column name to a dot-delimited path in the GraphQL filter object
const COLUMN_FIELD_MAPPING: Record<string, string> = {
  name: 'outputFile',
  status: 'status',
  type: 'createdByUser.name',
  exportName: 'exportConfiguration.name',
  createdAt: 'createdAt',
  initiatedBy: 'createdByUser.name',
  fileSize: 'outputFileByteSize',
}

// Maps MUI filter operators to GraphQL operators
export const MUI_OPERATOR_TO_GQL_OPERATOR: Record<string, string> = {
  contains: 'iContains',
  equals: 'exact',
  not: 'exact',
  startsWith: 'iStartsWith',
  endsWith: 'iEndsWith',
  isEmpty: 'isNull',
  isNotEmpty: 'isNull',
  isAnyOf: 'inList',
  after: 'gt',
  onOrAfter: 'gte',
  before: 'lt',
  onOrBefore: 'lte',
  is: 'exact',
}
const NEGATED_OPERATORS = ['not', 'isNotEmpty']
const SINGLE_OPERAND_OPERATORS = ['isEmpty', 'isNotEmpty']

// Convert's MUI sort represention to GraphQL
// Sample input - [{ field: 'type', sort: 'desc' }]
// Sample output - {exportConfiguration: {name: 'DESC'}}
const processSortModel = (sortModel: GridSortModel) => {
  if (sortModel.length === 0) return {}

  const model = sortModel[0]
  const field = COLUMN_FIELD_MAPPING[model.field] ?? model.field
  return set(field, model.sort?.toUpperCase(), {})
}

// Convert's MUI filter represention to GraphQL
// Sample input - { filters: [{ field: 'exportName', operator: 'equals', value: 'Export 1' }], search: '' }
// Sample output - {exportConfiguration: {name: {exact: 'Export 1'}}}
const processFilterModel = (filterModel: { filters: GridFilterItem[]; search: string }) => {
  const filters = filterModel.filters.reduce((acc, filter) => {
    const field = COLUMN_FIELD_MAPPING[filter.field] ?? filter.field
    const operator = MUI_OPERATOR_TO_GQL_OPERATOR[filter.operator]
    const isSingleOperandOperator = SINGLE_OPERAND_OPERATORS.includes(filter.operator)

    if (NEGATED_OPERATORS.includes(filter.operator))
      return set(`NOT.${field}`, { [operator]: isSingleOperandOperator ? true : filter.value }, acc)

    return set(field, { [operator]: isSingleOperandOperator ? true : filter.value }, acc)
  }, {})

  return filters
}

const Activity = ({ processId }: IProps) => {
  const { value: enableExportTaskReview } = useGate('enable-export-task-review')
  const { value: canApproveBaseRunsFlag } = useGate('can-approve-base-runs')
  const { showSnackbar } = useContext(SnackbarContext)
  const reactQueryClient = useQueryClient()

  const [readOnlyExportTaskId, setReadOnlyExportTaskId] = useState<string | null>(null)
  const [exportTaskInReview, setExportTaskInReview] = useState<IExportTaskInReview | null>(null)

  const [exportTaskBeingApproved, setExportTaskBeingApproved] =
    useState<IExportTaskToApprove | null>(null)

  const [sortModel, setSortModel] = useState<GridSortModel>(INITIAL_TABLE_STATE.sorting.sortModel)
  const [filterModel, setFilterModel] = useState<{ filters: GridFilterItem[]; search: string }>({
    filters: [],
    search: '',
  })
  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 100 })
  const [rowCount, setRowCount] = useState(0)

  const { data, isLoading } = useExportTasksWithReviewsQuery({
    first: pageModel.pageSize,
    after:
      pageModel.page === 0
        ? undefined
        : btoa(`arrayconnection:${pageModel.pageSize * pageModel.page - 1}`),
    filters: {
      processId: toGlobalId('ProcessType', processId),
      ...(filterModel.search
        ? {
            AND: {
              OR: {
                outputFile: {
                  iContains: filterModel.search,
                },
                OR: {
                  status: {
                    iContains: filterModel.search,
                  },
                  OR: {
                    exportConfiguration: {
                      name: {
                        iContains: filterModel.search,
                      },
                    },
                    OR: {
                      createdByUser: {
                        name: {
                          iContains: filterModel.search,
                        },
                      },
                    },
                  },
                },
              },
            },
          }
        : {}),
      ...processFilterModel(filterModel),
    },
    order: processSortModel(sortModel),
  })
  const { generateSignedUrl, isGenerateSignedUrlLoading } = useGenerateSignedUrl()
  const { retryExport, isExportRetryLoading } = useRetryExport({
    onError: (e) =>
      showSnackbar({
        message: `Failed to retry export: ${getErrorMessage(e)}`,
        variant: 'error',
      }),
    onSuccess: () => {
      showSnackbar({
        message: 'Export retried successfully',
        variant: 'success',
      })
    },
    onSettled: () => {
      reactQueryClient.invalidateQueries('ExportTasks')
    },
  })

  // Update local row count state
  useEffect(() => {
    const queryRowCount = data?.exportTasks?.totalCount
    setRowCount((prev) => (!isNil(queryRowCount) ? queryRowCount : prev))
  }, [data, setRowCount])

  const rows = useMemo<TRow[]>(
    () =>
      (data?.exportTasks.edges ?? []).map((t) => {
        const latestReview = t.node.lastExportReview
        return {
          id: t.node.id,
          name: t.node.outputFile?.split('/').pop() ?? '-',
          status: t.node.status.toLowerCase(),
          type: t.node.createdByUser ? 'Adhoc' : 'Scheduled',
          exportName: t.node.exportConfiguration.name ?? '-',
          createdAt: new Date(t.node.createdAt),
          initiatedBy: t.node.createdByUser?.name ?? '-',
          fileSize: t.node.outputFileByteSize ? computeFileSize(t.node.outputFileByteSize) : `-`,
          reviewStatus: latestReview?.status
            ? latestReview?.status
            : IDataDeliveryExportReviewStatusEnum.Pending,
          reviewedByUser: latestReview?.reviewedByUser?.name ?? '-',
          reviewedAt: latestReview?.createdAt ? new Date(latestReview?.createdAt) : undefined,
          notes: latestReview?.notes ? latestReview?.notes : '-',
        }
      }),
    [data]
  )

  const handleCopy = useCallback(
    async (exportTaskId: string) => {
      const data = await generateSignedUrl({ exportTaskId })
      navigator.clipboard.writeText(data.signed_url)
      showSnackbar({
        message: 'URL copied to clipboard',
        variant: 'success',
      })
    },
    [generateSignedUrl, showSnackbar]
  )

  const columns: GridColDef<TRow>[] = useMemo(
    () => [
      {
        field: 'exportName',
        headerName: 'Export Name',
        width: 150,
      },

      {
        field: 'name',
        headerName: 'File name',
        width: 150,
      },

      {
        field: 'status',
        display: 'flex',
        headerName: 'Status',
        width: 120,
        valueFormatter: (value) => startCase(value),
      },

      {
        field: 'type',
        display: 'flex',
        headerName: 'Export Type',
        width: 120,
      },

      {
        field: 'fileSize',
        display: 'flex',
        headerName: 'File Size',
        width: 100,
      },
      {
        field: 'createdAt',
        display: 'flex',
        headerName: 'Initiated At',
        width: 150,
        type: 'dateTime',
        renderCell: ({ row: { createdAt } }) => formatInTimeZone({ date: createdAt, tz: 'UTC' }),
      },
      {
        field: 'initiatedBy',
        display: 'flex',
        headerName: 'Initiated By',
        align: 'left',
        width: 150,
      },
      {
        field: 'reviewStatus',
        display: 'flex',
        headerName: 'Review',
        align: 'left',
        width: 120,
        renderCell: ({ row: { reviewStatus } }) => {
          const reviewStatusProps = REVIEW_STATUS_TEXT_PROPS.find((s) => s.status === reviewStatus)
          if (!reviewStatusProps) return null
          return (
            <Stack
              direction='row'
              alignItems='center'
              gap={1}
              sx={{ opacity: reviewStatusProps.opacity }}>
              {reviewStatusProps.icon}
              <Typography
                variant='caption'
                color={reviewStatusProps.color}
                sx={{ textTransform: 'capitalize' }}>
                {reviewStatus}
              </Typography>
            </Stack>
          )
        },
        sortable: false,
        filterable: false,
      },
      {
        field: 'reviewedByUser',
        display: 'flex',
        headerName: 'Reviewed by',
        align: 'left',
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: 'reviewedAt',
        display: 'flex',
        headerName: 'Reviewed at',
        align: 'left',
        width: 150,
        renderCell: ({ row: { reviewedAt } }) =>
          reviewedAt ? formatInTimeZone({ date: reviewedAt, tz: 'UTC' }) : '-',
        sortable: false,
        filterable: false,
      },
      {
        field: 'notes',
        display: 'flex',
        headerName: 'Notes',
        align: 'left',
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: 'actions',
        display: 'flex',
        align: 'left',
        type: 'actions',
        width: 200,
        getActions: ({ row }) =>
          compact([
            canApproveBaseRunsFlag && row.status === 'completed' ? (
              <Tooltip title='Approve base runs' key='Approve base runs'>
                <IconButton
                  size='small'
                  disabled={row.status !== 'completed'}
                  onClick={() => {
                    setExportTaskBeingApproved({
                      exportTaskId: row.id,
                      exportTaskName: row.exportName,
                    })
                  }}>
                  <DoneIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            ) : null,
            enableExportTaskReview ? (
              <Tooltip title='Review export' key='Review export'>
                <IconButton
                  size='small'
                  disabled={
                    row.status !== 'completed' ||
                    row.reviewStatus !== IDataDeliveryExportReviewStatusEnum.Pending
                  }
                  onClick={() => {
                    setExportTaskInReview({
                      exportTaskId: row.id,
                      exportTaskName: row.exportName,
                    })
                  }}>
                  <MarkChatReadOutlinedIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            ) : null,

            row.status === 'completed' ? (
              <Tooltip title='Download file' key='Download'>
                <IconButton
                  size='small'
                  disabled={isGenerateSignedUrlLoading}
                  onClick={async () => {
                    const data = await generateSignedUrl({ exportTaskId: fromGlobalId(row.id) })
                    if (data.signed_url) window.open(data.signed_url, '_blank')
                  }}>
                  <FileDownloadOutlinedIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            ) : null,

            row.status === 'completed' ? (
              <Tooltip title='Copy file URL' key='Copy'>
                <IconButton
                  size='small'
                  disabled={isGenerateSignedUrlLoading}
                  onClick={() => handleCopy(fromGlobalId(row.id))}>
                  <ContentCopyIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            ) : null,

            ['failed', 'no_data'].includes(row.status) ? (
              <Tooltip title='Retry export' key='Retry'>
                <IconButton
                  size='small'
                  disabled={isExportRetryLoading}
                  onClick={() => retryExport({ exportTaskId: fromGlobalId(row.id) })}>
                  <ReplayIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            ) : null,

            <Tooltip title='View configuration' key='View configuration'>
              <IconButton
                size='small'
                onClick={() => {
                  setReadOnlyExportTaskId(row.id)
                }}>
                <VisibilityOutlinedIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </Tooltip>,
          ]),
      },
    ],
    [
      enableExportTaskReview,
      canApproveBaseRunsFlag,
      isGenerateSignedUrlLoading,
      isExportRetryLoading,
      generateSignedUrl,
      handleCopy,
      retryExport,
    ]
  )

  return (
    <>
      <DataGridPro
        rows={rows}
        columns={columns}
        checkboxSelection={false}
        disableRowSelectionOnClick
        initialState={INITIAL_TABLE_STATE}
        paginationMode='server'
        filterMode='server'
        sortingMode='server'
        rowCount={rowCount}
        autoHeight
        loading={isLoading}
        slots={{
          toolbar: TimezoneToolbar as (p: ToolbarProps) => JSX.Element,
          noRowsOverlay: () => (
            <Stack direction='row' alignItems='center' pt='32px' pl='16px' gap='8px'>
              <InfoIcon sx={{ color: 'grey.600' }} />
              <Typography color='grey.600'>No exports have been executed yet.</Typography>
            </Stack>
          ),
        }}
        slotProps={{
          toolbar: {
            autoFocus: false,
          },
        }}
        sx={GRID_STYLES}
        onSortModelChange={(model) => setSortModel(model)}
        onFilterModelChange={(updatedFilters) =>
          setFilterModel({
            filters: updatedFilters.items,
            search: (updatedFilters.quickFilterValues ?? []).join(' ') ?? '',
          })
        }
        onPaginationModelChange={({ page, pageSize }) => setPageModel(() => ({ page, pageSize }))}
      />
      <ReviewExportTaskDialog
        exportTask={exportTaskInReview}
        setExportTaskId={setExportTaskInReview}
      />
      <ViewExportTaskDialog
        exportTaskId={readOnlyExportTaskId}
        setExportTaskId={setReadOnlyExportTaskId}
      />
      <BaseRunsApproveDialog
        exportTask={exportTaskBeingApproved}
        setExportTask={setExportTaskBeingApproved}
      />
    </>
  )
}

export { Activity }
