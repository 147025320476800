import { Environment } from './types'

export const getLanguages = (environments: Environment[]): string[] => [
  ...new Set(environments.map((item) => item.language.name)),
]

export const getVersions = (
  environments: Environment[],
  language: string | undefined
): string[] => [
  ...new Set(
    environments
      .filter((item) => item.language.name === language || !language)
      .map((item) => item.language.version)
  ),
]

export const getEnvironments = (
  environments: Environment[],
  language: string | undefined,
  version: string | undefined
): string[] =>
  environments
    .filter(
      (item) =>
        (item.language.name === language || !language) &&
        (item.language.version === version || !version)
    )
    .map((item) => item.environment_id)

export const convertOptions = (
  options: string[] | Record<string, string>
): Record<string, string> => {
  if (Array.isArray(options)) {
    return options.reduce((acc, option) => {
      acc[option] = option
      return acc
    }, {} as Record<string, string>)
  }

  return options
}
