import { formatInTimeZone } from '@invisible/common/date'
import { millisecondsToHours, millisecondsToMinutes } from 'date-fns'
import NextLink from 'next/link'
import React, { useState } from 'react'

import { BaseballCard } from '../baseball-card'
import { Button } from '../button'
import { copyToClipboard, formatCents, formatMillicents } from '../helpers'
import { Icons } from '../icons'
import { Tag, Tags } from '../tag'
import { useToasts } from '../toasts'

const formatTableCellMoney = (amount: number, type: 'cents' | 'millicents' = 'millicents') =>
  type === 'cents' ? formatCents(amount, true) : formatMillicents(amount)

const formatDurationInMs = (duration: number) => {
  const days = (millisecondsToHours(duration) / 24) | 0
  const hours = millisecondsToHours(duration) % 24
  const minutes = millisecondsToMinutes(duration) % 60

  const sign = duration < 0 ? '-' : ''
  const daysShown = days !== 0 ? `${Math.abs(days)}d ` : ''
  const hoursShown = hours !== 0 || (days !== 0 && minutes !== 0) ? `${Math.abs(hours)}h ` : ''
  const minutesShown = minutes !== 0 || (days === 0 && hours === 0) ? `${Math.abs(minutes)}m` : ''

  return `${sign}${daysShown}${hoursShown}${minutesShown}`.trim()
}

type CellProps = {
  children?: React.ReactNode
  flex?: 'row' | 'col'
  justifyCenter?: boolean
  itemsCenter?: boolean
  justifyBetween?: boolean
  title?: string
}

const Cell = ({ children, flex, justifyCenter, itemsCenter, justifyBetween, title }: CellProps) => {
  const FlexStyles = {
    row: 'flex',
    col: 'flex flex-col',
  }
  return (
    <div
      title={title}
      className={`gap-1 whitespace-nowrap px-4 py-3 ${FlexStyles[flex ?? 'row']} ${
        justifyCenter ? 'justify-center' : ''
      } ${itemsCenter ? 'items-center' : ''} ${justifyBetween ? 'justify-between' : ''}`}>
      {children}
    </div>
  )
}

type SpanColor = 'paragraphs' | 'muted' | 'danger'
type SpanSize = 'sm' | 'md'
type SpanProps = {
  children: (string | number) | (string | number)[]
  color?: SpanColor
  size?: SpanSize
}

const Span = ({ children, color = 'paragraphs', size = 'md' }: SpanProps) => {
  const ColorStyles: Record<SpanColor, string> = {
    muted: 'text-muted',
    paragraphs: 'text-paragraphs',
    danger: 'text-red-main',
  }
  const SizeStyles: Record<SpanSize, string> = {
    sm: 'text-xs',
    md: 'text-sm',
  }
  return (
    <span
      className={`${ColorStyles[color]} ${SizeStyles[size]} overflow-hidden text-ellipsis whitespace-nowrap`}>
      {children}
    </span>
  )
}

type StringNumberChild = (string | number) | (string | number)[]

type LinkProps = {
  children: StringNumberChild
  href: string
  target?: HTMLAnchorElement['target']
  rel?: HTMLAnchorElement['rel']
  id?: string
}

const Link = ({ children, href, target, rel, id }: LinkProps) => (
  <NextLink href={href}>
    <a
      id={id}
      className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium'
      target={target ?? '_self'}
      rel={rel}>
      {children}
    </a>
  </NextLink>
)

Cell.Span = Span
Cell.Link = Link

const CellDate = ({ date, timezone }: { date?: Date | null; timezone?: string }) => (
  <Cell>
    <Cell.Span>{date ? formatInTimeZone({ date, tz: timezone }) : '---'}</Cell.Span>
  </Cell>
)

const CellTime = ({ duration }: { duration: number }) => (
  <Cell>
    <Cell.Span>{formatDurationInMs(duration)}</Cell.Span>
  </Cell>
)

const CellMoney = ({
  amount,
  type = 'millicents',
}: {
  amount: number
  type?: 'cents' | 'millicents'
}) => (
  <Cell>
    <Cell.Span>{formatTableCellMoney(amount, type)}</Cell.Span>
  </Cell>
)

const CellTags = ({ tags }: { tags: string[] }) => (
  <Cell>
    <Tags>
      {tags.map((tag) => (
        <Tag color='theme'>{tag}</Tag>
      ))}
    </Tags>
  </Cell>
)

const CellUser = ({
  userId,
  email,
  name,
  image,
  slack,
}: {
  userId: string
  email: string
  name?: string | null
  image?: string | null
  slack?: string | null
}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <Cell itemsCenter>
      <div>
        <div onClick={() => setIsVisible(true)} className='hover:cursor-pointer'>
          {name ?? ''}
        </div>
        <BaseballCard
          image={image}
          name={name}
          email={email}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          userId={userId}
          slack={slack}
        />
      </div>
    </Cell>
  )
}

const CellNameId = ({
  name,
  id,
  href,
  showCopy = true,
}: {
  name: string
  id: string | number
  href?: string
  showCopy?: boolean
}) => {
  const { addToast } = useToasts()
  const handleCopyClick = () => {
    copyToClipboard(id)
    addToast(`${id} copied to clipboard!`, {
      appearance: 'success',
    })
  }

  return (
    <Cell flex='col'>
      {href ? (
        <Cell.Link id={String(id)} href={href}>
          {name}
        </Cell.Link>
      ) : (
        <div className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium'>
          {name}
        </div>
      )}
      <div className='flex items-center gap-2'>
        <Cell.Span color='muted' size='sm'>
          {id}
        </Cell.Span>
        {showCopy && (
          <Button
            variant='subtle'
            size='md'
            color='muted'
            shape='square'
            icon='CopyOutlineIcon'
            onClick={handleCopyClick}
          />
        )}
      </div>
    </Cell>
  )
}

const CellLink = (props: LinkProps) => (
  <Cell>
    <Cell.Link {...props} />
  </Cell>
)

const CellOnClick = ({ onClick, content }: { onClick: () => void; content?: string | null }) => (
  <Cell>
    <div
      onClick={onClick}
      className='text-theme-main overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium hover:cursor-pointer hover:underline'>
      {content}
    </div>
  </Cell>
)

const CellCopy = ({ content }: { content: string }) => {
  const { addToast } = useToasts()
  const handleCopyClick = () => {
    copyToClipboard(content)
    addToast(`${content} copied to clipboard!`, {
      appearance: 'success',
    })
  }

  return (
    <Cell justifyBetween itemsCenter>
      <Cell.Span color='paragraphs' size='md'>
        {content}
      </Cell.Span>
      <Button
        variant='subtle'
        size='md'
        color='paragraphs'
        shape='square'
        icon='CopyOutlineIcon'
        onClick={handleCopyClick}
      />
    </Cell>
  )
}

const CellIcon = ({ icon }: { icon: keyof typeof Icons }) => (
  <Cell justifyCenter>
    {Icons[icon]({
      className: 'w-4 h-4',
    })}
  </Cell>
)

export {
  Cell,
  CellCopy,
  CellDate,
  CellIcon,
  CellLink,
  CellMoney,
  CellNameId,
  CellOnClick,
  CellTags,
  CellTime,
  CellUser,
  formatTableCellMoney,
}
