import { StoreSlice } from '../index'

export type TIdleStatus = {
  isModalOpen: boolean
  isAutoSnoozed: boolean
  stepRunId?: string | null
  processId?: string | null
  stepName?: string | null
  timeoutTimestamp?: number | null
  isNotWorkingOnConfiguredStep: boolean
  isSnoozeRequestInProgress?: boolean
}

export interface IAutoSnoozeSlice {
  lastActivityDate: Date | null
  idleStatus: TIdleStatus
  setLastActivityDate: (lastActivityDate: Date) => void
  setIdleStatus: (idleStatus: TIdleStatus) => void
}

export const createAutoSnoozeSlice: StoreSlice<IAutoSnoozeSlice> = (set, get) => ({
  lastActivityDate: new Date(),
  idleStatus: {
    isModalOpen: false,
    isAutoSnoozed: false,
    stepRunId: null,
    stepName: null,
    timeoutTimestamp: null,
    isNotWorkingOnConfiguredStep: false,
    isSnoozeRequestInProgress: false,
  },

  // Functions
  setLastActivityDate: (lastActivityDate: Date) => {
    set((prev) => ({ ...prev, lastActivityDate }))
  },
  setIdleStatus: (idleStatus: TIdleStatus) => {
    set((prev) => ({ ...prev, idleStatus }))
  },
})
