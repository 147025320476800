import { formatInTimeZone } from 'date-fns-tz'

const formatDuration = (time: number, isMilliseconds?: boolean) => {
  const timeInSeconds = isMilliseconds ? time / 1000 : time
  // format time in seconds to mm:ss
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = Math.floor(timeInSeconds % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

// Converts an array of file name segements into the string representation
const getComputedName = ({
  segments,
  stepName,
  recordTime,
  companyName,
  recordingLength,
}: {
  segments: string[]
  stepName: string
  recordTime: Date
  companyName: string
  recordingLength: number
}) =>
  segments
    .map((segment) => {
      switch (segment) {
        case 'Task':
          return `${stepName}`
        case 'Date & time':
          return `on ${formatInTimeZone(recordTime, 'UTC', 'dd MMMM, yyyy')} at ${formatInTimeZone(
            recordTime,
            'UTC',
            'hh:mm a'
          )} UTC`
        case 'Client':
          return `for ${companyName}`
        case 'Duration':
          return `- ${formatDuration(recordingLength, true)}`
        default:
          return ''
      }
    })
    .join(' ')

export { formatDuration, getComputedName }
