import { constObjectToZod, z } from '@invisible/zod'

const CustomWACType = {
  KLARNA_COMPARE: 'klarnaCompare',
  BOLT_DOCUMENT_VERIFICATION_CRIMINAL_RECORD: 'boltDocumentVerificationCriminalRecord',
  BOLT_DOCUMENT_VERIFICATION_SIMPLE: 'boltDocumentVerificationSimple',
  BOLT_DOCUMENT_VERIFICATION_ID: 'boltDocumentVerificationId',
  BOLT_DOCUMENT_VERIFICATION_SELFIE: 'boltDocumentVerificationSelfie',
  GRUBHUB_FINAL_REVIEW_OPERATE_WAC: 'grubhubFinalReviewOperate',
  GRUBHUB_MENU_UPDATE_REVIEW_QA: 'grubhubMenuUpdateReviewQA',
  GRUBHUB_FINAL_REVIEW_QA_OPERATE_WAC: 'grubhubFinalReviewQAOperate',
  OPEN_AI_INSTRUCT_GPT_OPERATE_WAC: 'openAIInstructGPTOperate',
  RLHF_OPERATE: 'rlhfOperate',
  RLHF_SINGLE_USER: 'rlhfSingleUser',
  RLHF_SINGLE_USER_REVIEW: 'rlhfSingleUserReview',
  COHERE_OPERATE: 'cohereOperate',
  PROCESS_SECURITY_QUESTIONNAIRE: 'processSecurityQuestionnaire',
  RAG_RLHF: 'ragRLHF',
}

const WACType = {
  TEXT: 'text',
  RICH_TEXT: 'richText',
  EMBED: 'embed',
  TEXT_ANNOTATION: 'textAnnotation',
  FORM: 'form',
  CHILD_BASE_VIEW: 'childBaseView',
  INFORMATION_BASE: 'informationBase',
  SUBMIT_BUTTON: 'submitButton',
  EMAIL_FORM: 'emailForm',
  VIDEO: 'video',
  CODE2: 'code2',
  IMAGE: 'image',
  INFORMATION: 'information',
  JSON: 'JSON',
  SINGLE_VARIABLE_FORM: 'singleVariableForm',
  AUDIT: 'audit',
  QA: 'qa',
  SFT2: 'SFT2',
  RANKING: 'ranking',
  RLHF2: 'RLHF2',
  RLHF_PLUS: 'RLHFPlus',
  ORCHESTRATION2: 'orchestration2',
  SPELLCHECK: 'spellcheck',
  TABLE: 'table',
  RECORD_AUDIO: 'recordAudio',
  PROCESS_AUDIO: 'processAudio',
  ...CustomWACType,
} as const

const schema = constObjectToZod(WACType)
type TSchema = z.infer<typeof schema>

export { CustomWACType, schema, WACType }
export type { TSchema }
