import { logger } from '@invisible/logger/client'
import { Button } from '@invisible/ui/button'
import Editor from '@monaco-editor/react'
import CircularProgress from '@mui/material/CircularProgress'
import axios, { AxiosResponse } from 'axios'
import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../../config/env'
import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { CEMCodeStatus, ExecuteResponse, StatusResponse, TCodeWAC2 } from './types'

const FINISHED = [CEMCodeStatus.Failed, CEMCodeStatus.Success]
const DIVIDER_WIDTH = 8
const DIV_MIN_WIDTH = 80

type CodeAreaProps = {
  readOnly: boolean
  stepRunId: string
  config: TCodeWAC2
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
}

const getEditorOptions = (readOnly: boolean) => ({
  contextmenu: true,
  fontFamily: 'monospace',
  fontSize: 13,
  lineHeight: 24,
  hideCursorInOverviewRuler: true,
  minimap: {
    enabled: false,
  },
  scrollbar: {
    horizontalSliderSize: 4,
    verticalSliderSize: 18,
  },
  selectOnLineNumbers: true,
  roundedSelection: false,
  readOnly: readOnly,
  automaticLayout: true,
  wordWrap: 'on' as const,
})

const getDivWidths = (editorWidth: number, totalWidth: number) => {
  const newWidth = Math.max(
    DIV_MIN_WIDTH,
    Math.min(totalWidth - DIVIDER_WIDTH - DIV_MIN_WIDTH, editorWidth)
  )
  const editorWidthPercent = (newWidth / totalWidth) * 100
  const outputWidthPercent = ((totalWidth - newWidth - DIVIDER_WIDTH) / totalWidth) * 100
  return { editorWidth: editorWidthPercent, outputWidth: outputWidthPercent }
}

export const CodeArea = ({
  readOnly,
  stepRunId,
  config: { codeBaseRunVariableId, successBaseRunVariableId },
  updateBRV,
}: CodeAreaProps) => {
  const [editorWidth, setEditorWidth] = useState('100%')
  const [outputWidth, setOutputWidth] = useState<string | undefined>()
  const [isDragging, setIsDragging] = useState(false)
  const [output, setOutput] = useState<{ stdout: string; stderr: string }>()
  const [status, setStatus] = useState<CEMCodeStatus>(CEMCodeStatus.NotStarted)
  const resizerRef = useRef<HTMLDivElement | null>(null)
  const debounceTimeoutRef = useRef<number | null>(null)
  // codeVar is the last submitted value of code, this is used to prevent sending updates
  // that do not change the value on the backend
  const updateState = useBaseRunVariablesStore((state) => state.updateState)
  const language = useBaseRunVariablesStore((state) => state.language)
  const code = useBaseRunVariablesStore((state) => state.code)
  const environment = useBaseRunVariablesStore((state) => state.environment)
  const isSuccessRaw = useBaseRunVariablesStore((state) => state.isSuccess)
  const isSuccess = isSuccessRaw === 'true'
  const codeVar = useRef(code)

  const isFinished = FINISHED.includes(status)
  const isStarted = status !== CEMCodeStatus.NotStarted

  useEffect(() => {
    if (!isFinished) {
      return
    }
    const currentSuccess = status === CEMCodeStatus.Success ? 'true' : 'false'
    if (isSuccessRaw !== currentSuccess) {
      updateBRV(successBaseRunVariableId, currentSuccess)
      updateState({ isSuccess: currentSuccess })
    }
  }, [status])

  useEffect(() => {
    if (!isStarted || resizerRef === null || resizerRef.current === null) {
      return
    }
    const element = resizerRef.current
    const totalWidth = element.parentElement
      ? (element.parentElement as HTMLElement).offsetWidth
      : 0
    const widths = getDivWidths(totalWidth / 2, totalWidth)
    setEditorWidth(`${widths.editorWidth}%`)
    setOutputWidth(`${widths.outputWidth}%`)
  }, [isStarted])

  const instantCodeUpdate = useCallback(
    (value: string | undefined) => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
      }
      if (value === codeVar.current) {
        return
      }
      codeVar.current = value
      updateBRV(codeBaseRunVariableId, value)
    },
    [codeBaseRunVariableId, updateBRV]
  )

  // Handle drag event for resizing panels
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (resizerRef === null || resizerRef.current === null) {
      return
    }
    setIsDragging(true)
    const element = resizerRef.current
    const startX = e.clientX
    const initialWidth = element.previousElementSibling
      ? (element.previousElementSibling as HTMLElement).offsetWidth
      : 0
    const totalWidth = element.parentElement
      ? (element.parentElement as HTMLElement).offsetWidth
      : 0

    const onMouseMove = (e: MouseEvent) => {
      const newWidth = initialWidth + (e.clientX - startX)
      const widths = getDivWidths(newWidth, totalWidth)
      setEditorWidth(`${widths.editorWidth}%`)
      setOutputWidth(`${widths.outputWidth}%`)
    }

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      setIsDragging(false)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const statusCode = useCallback(
    (executionId: string) => {
      const intervalId = setInterval(() => {
        axios
          .get(
            `${NEXT_PUBLIC_CONCORDE_URL}/api/cem/status/${executionId}?step-run-id=${stepRunId}`,
            { withCredentials: true }
          )
          .then((response: AxiosResponse<StatusResponse>) => {
            const metadata = response.data.execution_metadata
            if (['success', 'failed'].includes(metadata.execution_state)) {
              setOutput({
                stdout: metadata.stdout,
                stderr: metadata.stderr,
              })
              setStatus(
                metadata.execution_state === 'success'
                  ? CEMCodeStatus.Success
                  : CEMCodeStatus.Failed
              )
              clearInterval(intervalId)
            }
          })
          .catch((error) => {
            logger.error('Error checking code status:', error)
            setStatus(CEMCodeStatus.Failed)
            clearInterval(intervalId)
          })
      }, 3000)
    },
    [stepRunId]
  )

  const executeCode = useCallback(() => {
    setStatus(CEMCodeStatus.Executing)
    instantCodeUpdate(code)
    axios
      .post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/cem/execute`,
        { environment_id: environment, code, step_run_id: stepRunId, language },
        { withCredentials: true }
      )
      .then((response: AxiosResponse<ExecuteResponse>) => {
        if (response.status === 200 && response.data.status === 'success') {
          statusCode(response.data.execution_id)
        } else {
          setStatus(CEMCodeStatus.Failed)
        }
      })
      .catch((error) => {
        logger.error('Error executing code:', error)
        setStatus(CEMCodeStatus.Failed)
      })
  }, [code, environment, stepRunId, statusCode, instantCodeUpdate])

  const handleEditorChange = useCallback(
    async (value: string | undefined) => {
      if (isSuccess) {
        updateBRV(successBaseRunVariableId, 'false')
        updateState({ isSuccess: 'false' })
      }

      // If the old timeout has not sent yet we need to cancel it so that it is not sent
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
      }

      // Send the update in 5 seconds
      debounceTimeoutRef.current = window.setTimeout(() => {
        if (value !== codeVar.current) {
          codeVar.current = value
          updateBRV(codeBaseRunVariableId, value)
        }
      }, 5000)

      updateState({ code: value })
    },
    [codeBaseRunVariableId, isSuccess, successBaseRunVariableId, updateBRV, updateState]
  )

  return (
    <div>
      {/* Inject CSS Styles */}
      <style>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #555 #29272F;
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 12px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #29272F;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #555;
          border-radius: 6px;
          border: 3px solid #29272F;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: #777;
        }
      `}</style>
      <div className='flex w-full max-w-full'>
        <div
          style={{ width: editorWidth }}
          className={clsx({ 'transition-all duration-500': !isDragging })}>
          <Editor
            height='30vh'
            theme='vs-light'
            defaultLanguage='python'
            language={language?.toLowerCase() || 'python'}
            value={code || ''}
            options={getEditorOptions(readOnly)}
            onChange={handleEditorChange}
          />
        </div>
        {isStarted && (
          <div
            ref={resizerRef}
            className='w-2 cursor-col-resize bg-gray-400'
            onMouseDown={handleMouseDown}
          />
        )}
        {isStarted && (
          <div
            className={clsx('min-w-16 h-[30vh] max-w-full flex-grow bg-[#29272F]', {
              'transition-all duration-500': !isDragging,
            })}
            style={outputWidth ? { width: outputWidth } : {}}>
            <div className='relative h-full pt-2 pl-6 text-white'>
              <h3 className='border-2 border-[#FFFFFF]'>Output</h3>
              <div className='custom-scrollbar h-full max-h-[24vh] overflow-auto pt-2'>
                {output?.stdout && (
                  <div className='text-wrap whitespace-pre-wrap'>{output.stdout}</div>
                )}
                {output?.stderr && (
                  <div className='text-wrap whitespace-pre-wrap text-red-500'>{output.stderr}</div>
                )}
              </div>
              {status === CEMCodeStatus.Executing && (
                <div className='absolute inset-0 flex items-center justify-center'>
                  <CircularProgress size={100} color='primary' />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='my-4 flex items-center'>
        <div>
          <Button
            size='md'
            onClick={executeCode}
            disabled={code === '' || status === CEMCodeStatus.Executing || !environment}>
            Check code
          </Button>
        </div>
        {isFinished && (
          <p className='ml-2'>
            Status:{' '}
            <span
              className={clsx({
                'text-[#D32F2F]': status === CEMCodeStatus.Failed,
                'text-[#2E7D32]': status === CEMCodeStatus.Success,
              })}>
              {status}
            </span>
          </p>
        )}
      </div>
    </div>
  )
}
